import { ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import { useSearchParams } from "react-router-dom";
import { edgeTypes, nodeTypes } from "../helpers/edgeAndNodeTypes";
import useCollapseAndExpand from "../hooks/useCollapseAndExpand";
import useExport from "../hooks/useExport";
import useFetchAndSubscribeToMindMap from "../hooks/useFetchAndSubscribeToMindMap";
import useGlobalContext from "../hooks/useGlobalContext";
import useHandleMessage from "../hooks/useHandleMessage";
import useHandleNodeClick from "../hooks/useHandleNodeClick";
import useHandleSelectedNode from "../hooks/useHandleSelectedNode";
import useNavigateToNodes from "../hooks/useNavigateToNodes";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import ControlsPanel from "./ControlsPanel";

export default function MindMap() {
  const [edges, , onEdgesChange] = useEdgesState([]);
  const [nodes, , onNodesChange] = useNodesState([]);

  const [searchParams] = useSearchParams();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { collapse, expand } = useCollapseAndExpand();
  const { handleNodeClick } = useHandleNodeClick();
  const { isEditing } = useGlobalContext();
  const { navigateToNextNode, navigateToPreviousNode } = useNavigateToNodes();

  const defaultViewport = {
    x: typeof window !== "undefined" ? window.innerWidth / 2 : 0,
    y: typeof window !== "undefined" ? window.innerHeight / 2 : 0,
    zoom: 0.6,
  };

  const showControls = searchParams.get("controls") === "true";

  useExport();

  useFetchAndSubscribeToMindMap();

  useHandleMessage();

  useHandleSelectedNode();

  return (
    <ReactFlow
      className="bg-custom-background"
      defaultViewport={defaultViewport}
      edgeTypes={edgeTypes}
      edges={edges}
      maxZoom={0.8}
      minZoom={0.1}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable
      onEdgesChange={onEdgesChange}
      onNodeClick={handleNodeClick}
      onNodeDragStop={persistEditedEdgesAndNodes}
      onNodesChange={onNodesChange}
      panOnScroll
      proOptions={{ account: "paid-pro", hideAttribution: true }}
      selectNodesOnDrag={false}
      zoomOnDoubleClick={!isEditing}
    >
      {showControls && (
        <ControlsPanel
          collapse={collapse}
          expand={expand}
          navigateToNextNode={navigateToNextNode}
          navigateToPreviousNode={navigateToPreviousNode}
        />
      )}
    </ReactFlow>
  );
}

import { Node, useReactFlow } from "@xyflow/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../helpers/supabase";
import useLayOutWithD3Hierarchy from "./useLayOutWithD3Hierarchy";

export default function useFetchAndSubscribeToMindMap() {
  const { mindMapId } = useParams();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { setLaidOutNodes } = useLayOutWithD3Hierarchy();

  const channelName = `mind_map_${mindMapId}_updates`;

  const createInitialNode = (userPrompt: string) => {
    return {
      data: { initial: true, label: userPrompt },
      id: "1",
      position: { x: 0, y: 0 },
      type: "level-1",
    };
  };

  function getCollapsedNodes(nodes: Node[]) {
    return nodes.map((node) => {
      const hidden =
        ["level-3", "level-4"].includes(node.type as string) ||
        node.id.split(".").length > 2;

      return { ...node, hidden };
    });
  }

  async function fetchMindMap() {
    const { data, error } = await supabase
      .from("mind_maps")
      .select(
        "edited_react_flow_edges, edited_react_flow_nodes, react_flow_edges, react_flow_nodes, user_prompt",
      )
      .eq("id", mindMapId);

    if (error) {
      console.error("Error fetching mind map", error);
      return;
    }

    const mindMap = data[0];

    const reactFlowEdges =
      mindMap.edited_react_flow_edges || mindMap.react_flow_edges || [];

    const reactFlowNodes =
      mindMap.edited_react_flow_nodes || mindMap.react_flow_nodes || [];

    const reactFlowNodesOrInitialNode =
      reactFlowNodes.length > 0
        ? reactFlowNodes
        : [createInitialNode(mindMap.user_prompt)];

    const collapsedNodes = getCollapsedNodes(reactFlowNodesOrInitialNode);

    setLaidOutNodes({ edges: reactFlowEdges, nodes: collapsedNodes });

    setEdges(reactFlowEdges);
  }

  // NOTE: Refetch the mind map after some time if the edges and nodes are still
  // empty as sometimes Supabase's Realtime feature is unreliable.
  function refetchMindMap() {
    const edges = getEdges();
    const nodes = getNodes();

    if (edges.length === 0 || nodes.length === 0) {
      fetchMindMap();
    }
  }

  function subscribeToMindMap() {
    supabase
      .channel(channelName)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          filter: `id=eq.${mindMapId}`,
          schema: "public",
          table: "mind_maps",
        },
        (payload) => {
          const mindMap = payload.new;

          const reactFlowEdges =
            mindMap.edited_react_flow_edges || mindMap.react_flow_edges || [];

          const reactFlowNodes =
            mindMap.edited_react_flow_nodes || mindMap.react_flow_nodes || [];

          if (reactFlowNodes.length > 0) setNodes(reactFlowNodes);
          if (reactFlowEdges.length > 0) setEdges(reactFlowEdges);
        },
      )
      .subscribe();
  }

  function unsubscribeFromMindMap() {
    supabase.channel(channelName).unsubscribe();
  }

  useEffect(() => {
    fetchMindMap();

    subscribeToMindMap();

    const timeout = setTimeout(() => {
      refetchMindMap();
    }, 7000);

    return () => {
      clearTimeout(timeout);
      unsubscribeFromMindMap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

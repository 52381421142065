import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { Panel, useReactFlow } from "@xyflow/react";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useAddVersion from "../hooks/useAddVersion";
import useGlobalContext from "../hooks/useGlobalContext";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import usePostIsEditingToReactNativeWebView from "../hooks/usePostIsEditingToReactNativeWebView";

type Props = {
  collapse: () => void;
  expand: () => void;
  navigateToNextNode: () => void;
  navigateToPreviousNode: () => void;
};

export default function ControlsPanel({
  collapse,
  expand,
  navigateToNextNode,
  navigateToPreviousNode,
}: Props) {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const postIsEditingToReactNativeWebView =
    usePostIsEditingToReactNativeWebView();
  const { fitView, getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { t } = useTranslation();

  const {
    isEditable,
    isEditing,
    isExpanded,
    isInteracted,
    setIsEditing,
    setIsInteracted,
    setVersionIndex,
    userIsPro,
    versionIndex,
    versions,
  } = useGlobalContext();

  const handleCancelClick = () => {
    setIsEditing(false);

    postIsEditingToReactNativeWebView(false);
  };

  const handleEditClick = () => {
    postIsEditingToReactNativeWebView(true);

    if (!userIsPro) return;

    if (!isInteracted) setIsInteracted(true);

    if (versionIndex === -1) {
      addVersion({ edges: getEdges(), nodes: getNodes() });
    }

    setIsEditing(true);
  };

  const handleExpandClick = () => {
    return isExpanded ? collapse() : expand();
  };

  const handleNextClick = () => {
    navigateToNextNode();
  };

  const handlePreviousClick = () => {
    navigateToPreviousNode();
  };

  const handleRedoClick = () => {
    const nextVersionIndex = versionIndex + 1;
    const nextVersion = versions[nextVersionIndex];
    setVersionIndex(nextVersionIndex);
    setEdges(nextVersion.edges);
    setNodes(nextVersion.nodes);

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      persistEditedEdgesAndNodes();

      fitView({ duration: 1000 });
    }, 0);
  };

  const handleUndoClick = () => {
    const previousVersionIndex = versionIndex - 1;
    const previousVersion = versions[previousVersionIndex];
    setVersionIndex(previousVersionIndex);
    setEdges(previousVersion.edges);
    setNodes(previousVersion.nodes);

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      persistEditedEdgesAndNodes();

      fitView({ duration: 1000 });
    }, 0);
  };

  const marginBottom = () => {
    if (isAndroid || isIOS) {
      return "mb-40";
    }

    return "mb-4";
  };

  const panelButtonClassName = isEditing
    ? "panel-button--edit"
    : "panel-button";

  return (
    <Panel
      className={`m-0 ${marginBottom()} flex overflow-hidden whitespace-nowrap rounded-xl`}
      position="bottom-center"
      style={{ zIndex: 2000 }}
    >
      {!isEditing && (
        <button className={panelButtonClassName} onClick={handleExpandClick}>
          {isExpanded ? (
            <ArrowsPointingInIcon className="panel-button-icon" />
          ) : (
            <ArrowsPointingOutIcon className="panel-button-icon" />
          )}
        </button>
      )}

      {isEditable && !isEditing && (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-gray`}
          onClick={handleEditClick}
        >
          <PencilIcon className="panel-button-icon" />
        </button>
      )}

      {isEditing ? (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-gray`}
          disabled={versions.length < 2 || versionIndex === 0}
          onClick={handleUndoClick}
        >
          <ArrowUturnLeftIcon className="panel-button-icon" />
        </button>
      ) : (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-gray`}
          onClick={handlePreviousClick}
        >
          <ArrowLeftIcon className="panel-button-icon" />
        </button>
      )}

      {isEditing ? (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-gray`}
          disabled={versions.length < 2 || versionIndex === versions.length - 1}
          onClick={handleRedoClick}
        >
          <ArrowUturnRightIcon className="panel-button-icon" />
        </button>
      ) : (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-gray`}
          onClick={handleNextClick}
        >
          <ArrowRightIcon className="panel-button-icon" />
        </button>
      )}

      {isEditing && (
        <button
          className={`${panelButtonClassName} border-custom-gray`}
          onClick={handleCancelClick}
        >
          {t("done")}
        </button>
      )}
    </Panel>
  );
}

import * as Sentry from "@sentry/react";
import getUserId from "./getUserId";
import { supabase } from "./supabase";

export default async function getMindMapExpansionsCount(): Promise<
  number | null
> {
  const userId = await getUserId();

  if (!userId) return null;

  const twentyFourHoursAgo = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000,
  );

  const { count, error } = await supabase
    .from("mind_map_expansions")
    .select("*", { count: "exact", head: true })
    .eq("user_id", userId)
    .gte("created_at", twentyFourHoursAgo.toISOString());

  if (error) {
    Sentry.captureException(
      `Failed to get mind map expansions count: ${error.message}`,
    );

    return null;
  }

  return count;
}

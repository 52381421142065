import * as Sentry from "@sentry/react";
import { supabase } from "./supabase";

export default async function getUserId() {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    Sentry.captureException(`Failed to get user id: ${error.message}`);
  }

  return data?.session?.user?.id;
}
